import { getGatsbyImageData } from "@imgix/gatsby"
import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyGearPromo } from "../../../slices/PageDataBodyGearPromo"

const gearImage = (data: { src: string; aspectRatio: number }) => {
	return getGatsbyImageData({
		width: 500,
		layout: "constrained",
		imgixParams: { fit: "facearea", facepad: "6" },
		...data,
	})
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyGearPromo
				heading={lorem.words(5)}
				copyText={
					[
						{ text: lorem.sentences(2), type: "paragraph", spans: [] },
					] as pt.RichTextField
				}
				bulletItems={[lorem.words(4), lorem.words(2), lorem.words(3)].map(
					(words) => ({
						bullet: words,
					}),
				)}
				gearOneImage={gearImage({
					src: "https://images.unsplash.com/photo-1581122584612-713f89daa8eb",
					aspectRatio: 672 / 1007,
				})}
				gearTwoImage={gearImage({
					src: "https://images.unsplash.com/photo-1517466787929-bc90951d0974",
					aspectRatio: 670 / 1008,
				})}
				gearThreeImage={gearImage({
					src: "https://images.unsplash.com/photo-1566351523345-e081e56e9754",
					aspectRatio: 671 / 1008,
				})}
				gearOneAlt=""
				gearTwoAlt=""
				gearThreeAlt=""
				buttonOneLink="#"
				buttonOneText="Learn More"
				buttonTwoLink="#"
				buttonTwoText="Read More"
			/>
		</CmsGuideLayout>
	)
}

export default Component
